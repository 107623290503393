<template>
  <div class="chessHand"
       style="padding-bottom: 20px">
    <div v-title
         data-title="每月棋牌流水福利"></div>

    <!-- <van-nav-bar
      title="欢乐棋牌"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Monthlyflowchess.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">所有棋牌场馆</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.date_time }}</span>
            </div>
            <div class="title-item">
              <p>领取时间:</p>
              <span style="color:var(--li-colortext);">每月一次</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">当月棋牌有效投注额<span v-if="datalist.moneyList">≥{{fmoney(datalist.moneyList[0].money)}}</span>，每月即可参加。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <!-- <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  上周体育流水
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  当前存款金额
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datalist.beat }}元</td>
                <td>{{ datalist.apply_amount }}元</td>
              </tr>
            </tbody>
          </table> -->
          <div style="text-align: center;color: #333;font-size: 14px;margin-top: 15px;">当月有效投注: <span style="color: #4581fa;" v-if="datalist.beated">{{ fmoney(datalist.beated.Chess)  }}</span></div>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  棋牌有效投注
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  兑换彩金
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.moneyList"
                  :key="index">
                <td>≥{{ fmoney(item.money) }}</td>
                <td>{{ item.player_money }}</td>
                <td v-if="index == 0"
                    :rowspan="datalist.moneyList.length">{{datalist.beat_muti}}倍</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
          </div>

          <div class="rule-content-title4"
               style="margin-top: 0;"></div>
               <p style="font-size: 14px;text-align: center;margin-top: 10px;color:var(--td-colortext);">仅展示近10笔，最长保留30天</p>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  时间
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  红利
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  状态
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.applyed_data"
                  :key="index">
                <td>{{ item.created_at }}</td>
                <td>{{ item.bouns_amout }}</td>
                <td>{{ item.status == 0 ? '审核中' : item.remark }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                指定场馆：任何棋牌场馆。
              </li>
              <li>
                <span>2.</span>
                此活动新老会员每月可申请一次，当月棋牌有效投注流水不包括任何优惠红利投注产生的流水。此红利活动不得与每周私享会活动同时使用。
              </li>
              <li>
                <span>3.</span>
                【领取要求】：在{{ datalist.date_time }}期间，根据会员在贝宝所有棋牌场馆里累计的有效投注额即可兑换对应的档位彩金，每月最多可兑换一次彩金（例如：会员当月贝宝棋牌300W有效流水，在瓦力棋牌场馆200W有效流水，当月累计500W有效流水，可兑换彩金{{datalist.num}}元，彩金x{{datalist.beat_muti}}倍流水即可提款）。<br/>
                计算公式如下:<br/>
                有效投注额为：{{datalist.num}} × {{datalist.beat_muti}} = {{ fmoney(datalist.num*datalist.beat_muti) }}
              </li>
              <li>
                <span>4.</span>
                【领取时间】：满足条件后即可自助【兑换彩金】，兑换后24小时内派发彩金至账户钱包，若当月没有兑换将视为放弃彩金。
              </li>
              <!-- <li>
                <span>5.</span>
                所有的棋牌场馆仅对已结算并产生输赢结果的投注额进行计算，任何低于欧洲盘1.5或香港盘0.5水位的投注、提前结算注单、走盘、串关、取消的赛事中同时投注对冲盘口，将不计算在有效投注额内。
              </li> -->
              <li>
                <span>5.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>6.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>9.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.shuzu"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
    }
  },
  onShow() { },

  mounted() {
    let that = this
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (window.localStorage.getItem('opentype') == 'h5') {
      window.addEventListener('message', function (e) {
        // 数据返回
        if (e.data.act == 'BeatGift') {
          if (e.data.status == 1) {
            that.getdata()
          }
        }
      })
    }
  },

  methods: {
    fmoney(s) {
      s = (s + '').replace(/[^\d\.-]/g, "") + "";
      var l = s.split(".")[0].split("").reverse();
      var t = "";
      for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
      }

      return t.split("").reverse().join("")
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata() {
      var _this = this
      // 2真人，3电竞，4棋牌，5体育，6电子
      var data = '?type=BeatGift&beat_act=4'
      getdata(data).then((res) => {
        this.datalist = res.message
        this.datalist.moneyList = this.datalist.moneyList.reverse()
        _this.shuaxin()
      })
    },
    shuaxin() {
      let that = this
      window.FunctionUniapp = function (arg) {
        if (JSON.stringify(arg) == 1) {
          // that.luckyNum++
          that.getdata()
        }
      }
    },
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay() {
      this.showPicker = true
    },
    confirm(e) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'BeatGift',
                  beatact: 4,
                  gameid: e.id,
                  now_money: this.datalist.beated.Chess,
                  name:this.datalist.name
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'vipActapplyobject',
              actobject: {
                act: 'BeatGift',
                beatact: 4,
                gameid: e.id,
                now_money: this.datalist.beated.Chess,
                name:this.datalist.name
              },
            }
          })
        }
      })

      this.showPicker = false
    },
    cancel() {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    > div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
